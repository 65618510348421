.progress {
    width: 100%;
    overflow: hidden;
    height: 24px;
    box-shadow: none;
    background-color: var(--color-grey-01);
    border-radius: var(--border-radius);
    margin-bottom: 24px;

    .progress-bar {
        box-shadow: none;
        height: 100%;
        font-size: 12px;
        text-align: center;
        color: white;
        line-height: 24px;
        transition: width .6s ease;
        background-color: var(--progress-color, transparent);
        width: calc(var(--progress-value, 0) * 1%);
    }
}